import { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import useAuth from '../../hooks/useAuth';
import useAvatarUrl from '../../hooks/useAvatarUrl';

const Item = ({ title, to, icon, selected, setSelected, toggleSidebar }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => {
                setSelected(title);
                if (toggleSidebar) {
                    toggleSidebar();
                }
            }}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = ({ isSidebarOpen, isMobile, toggleSidebar }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState('Dashboard');
    const { auth } = useAuth();
    const fetchAvatarUrl = useAvatarUrl();

    useEffect(() => {
        if (auth.user_id && !auth.avatarUrl) {
            fetchAvatarUrl(auth.user_id);
        }
    }, [auth.user_id, auth.avatarUrl, fetchAvatarUrl]);

    const checkUrlExpiration = () => {
        if (auth.avatarUrl) {
            try {
                const url = new URL(auth.avatarUrl);
                const amzDate = url.searchParams.get('X-Amz-Date');
                const amzExpires = parseInt(url.searchParams.get('X-Amz-Expires'), 10);

                if (amzDate && !isNaN(amzExpires)) {
                    const urlTimestamp = new Date(
                        Date.UTC(
                            parseInt(amzDate.slice(0, 4)),
                            parseInt(amzDate.slice(4, 6)) - 1,
                            parseInt(amzDate.slice(6, 8)),
                            parseInt(amzDate.slice(9, 11)),
                            parseInt(amzDate.slice(11, 13)),
                            parseInt(amzDate.slice(13, 15))
                        )
                    );

                    const expirationTime = new Date(urlTimestamp.getTime() + amzExpires * 1000);
                    const currentTime = new Date();
                    if (currentTime >= expirationTime) {
                        fetchAvatarUrl(auth.user_id);
                    }
                }
            } catch (error) {
                console.error('Error checking avatar URL expiration:', error);
            }
        }
    };

    useEffect(() => {
        if (auth.avatarUrl) {
            checkUrlExpiration();
        }
    }, [auth.avatarUrl]);

    useEffect(() => {
        if (isMobile) {
            setIsCollapsed(false);
        }
    }, [isMobile]);

    const handleToggleCollapse = () => {
        if (isMobile) {
            toggleSidebar();
        } else {
            setIsCollapsed(!isCollapsed);
        }
    };

    return (
        <Box
            sx={{
                display: isMobile && !isSidebarOpen ? 'none' : 'block',
                position: isMobile ? 'fixed' : 'relative',
                top: 0,
                left: 0,
                width: isCollapsed ? '80px' : '250px',
                height: '100vh',
                zIndex: isMobile ? 1300 : 1,
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`,
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important',
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important',
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important',
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important',
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed} toggled={isSidebarOpen}>
                <Menu iconShape="square">
                    <MenuItem
                        onClick={handleToggleCollapse}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                >
                                    Rise Plumbing
                                </Typography>
                                <IconButton
                                    onClick={handleToggleCollapse}
                                >
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={auth.avatarUrl}
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: '10px 0 0 0' }}
                                >
                                    {auth.name}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color={colors.greenAccent[500]}
                                >
                                    {auth.job_title}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item
                            title="Dashboard"
                            to="/dashboard"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '15px 0 5px 20px' }}
                        >
                            Work
                        </Typography>
                        <Item
                            title="Calendar"
                            to="/calendar"
                            icon={<CalendarTodayOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Manage Warranties"
                            to="/warranty"
                            icon={<RequestPageOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Manage Inspections"
                            to="/inspection"
                            icon={<ManageSearchOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Manage Work"
                            to="/work"
                            icon={<WorkIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Manage Properties"
                            to="/property"
                            icon={<HolidayVillageIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Manage Documents"
                            to="/document"
                            icon={<DescriptionIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '15px 0 5px 20px' }}
                        >
                            Financials
                        </Typography>
                        <Item
                            title="Invoices Balances"
                            to="/invoices"
                            icon={<ReceiptOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '15px 0 5px 20px' }}
                        >
                            HR
                        </Typography>
                        <Item
                            title="Manage People"
                            to="/people"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Manage Team"
                            to="/team"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Profile Form"
                            to="/form"
                            icon={<PersonOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '15px 0 5px 20px' }}
                        >
                            Charts
                        </Typography>
                        <Item
                            title="Bar Chart"
                            to="/bar"
                            icon={<BarChartOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Pie Chart"
                            to="/pie"
                            icon={<PieChartOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Line Chart"
                            to="/line"
                            icon={<TimelineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                        <Item
                            title="Geography Chart"
                            to="/geography"
                            icon={<MapOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            toggleSidebar={toggleSidebar}
                        />
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
