import { useState, useEffect } from 'react';
import { formatDate } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Box, List, ListItem, ListItemText, Typography, useTheme, Snackbar, Alert, Select, MenuItem, FormControl, InputLabel, OutlinedInput, IconButton, Drawer } from '@mui/material'; // Updated importsimport Header from '../../components/Header';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import useEventData from '../../hooks/useEventData'; // Import the hook
import dayjs from 'dayjs';
import CircularLoading from '../global/CircularLoading';
import AddEventModal from './AddEventModal';
import ViewEventModal from './ViewEventModal';
import MenuIcon from '@mui/icons-material/Menu';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedNames) {
    return {
      fontWeight: selectedNames.includes(name) ? 600 : 400, // 500 for medium, 400 for regular
    };
  }

const Calendar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { loading, dropdownData, fetchEvents } = useEventData(); // Use the hook to fetch events

    const [currentEvents, setCurrentEvents] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectedPerson, setSelectedPerson] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [people, setPeople] = useState([]);
    const [teams, setTeams] = useState([]);
    const [types, setTypes] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]); // Store filtered events
    const [calendarKey, setCalendarKey] = useState(0); // Add a state for key to force re-render
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [isViewModalOpen, setViewModalOpen] = useState(false); // For ViewEventModal
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility
    const [currentView, setCurrentView] = useState(window.innerWidth < 1300 ? 'listMonth' : 'dayGridMonth');

    const handleViewChange = (viewInfo) => {
        // Only update if the user manually switches to a different view
        if (viewInfo.view.type !== currentView) {
            setCurrentView(viewInfo.view.type);
            setCalendarKey(prevKey => prevKey + 1); // Force re-render when view changes
        }
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1300);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Filter function
    useEffect(() => {
        const filterEvents = () => {
            let filtered = currentEvents;

            // Filter by People
            if (selectedPerson.length > 0) {
                filtered = filtered.filter(event =>
                    event.persons.some(person => selectedPerson.includes(`${person.first_name} ${person.last_name}`))
                );
            }

            // Filter by Teams
            if (selectedTeam.length > 0) {
                filtered = filtered.filter(event =>
                    event.teams.some(team => selectedTeam.includes(team.name))
                );
            }

            // Filter by Work Type
            if (selectedType.length > 0) {
                filtered = filtered.filter(event =>
                    selectedType.includes(event.work_type)
                );
            }

            setFilteredEvents(filtered);
        };

        filterEvents();
        setCalendarKey(prevKey => prevKey + 1);
    }, [selectedPerson, selectedTeam, selectedType, currentEvents]);

    const handleSelectChange = (setter) => (event) => {
        const {
            target: { value },
        } = event;
        setter(typeof value === 'string' ? value.split(',') : value);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (snackbarOpen) {
            fetchEvents();
        }

        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen, fetchEvents]);

    useEffect(() => {
        if (dropdownData && !loading) {
            const formattedEvents = dropdownData.map((event) => ({
                id: event.id,
                title: event.title,
                start: dayjs(event.start_date).toISOString(), // Ensure the date is in the correct format
                end: dayjs(event.end_date).toISOString(), // Convert to ISO format
                allDay: dayjs(event.start_date).hour() === 0 && dayjs(event.end_date).hour() === 0, // Assuming your events are not all-day by default
                persons: event.persons || [],
                teams: event.teams || [],
                status: event.status,
                work_type: event.work_type || '',
                address: event.address || {},
                description: event.description || '',
            }));
            setCurrentEvents(formattedEvents);

            // Extract people, teams, and types
            const uniquePeople = new Set();
            const uniqueTeams = new Set();
            const uniqueTypes = new Set();

            formattedEvents.forEach(event => {
                // Add persons from event
                event.persons.forEach(person => {
                    uniquePeople.add(`${person.first_name} ${person.last_name}`);
                });

                // Add teams and their members
                event.teams.forEach(team => {
                    uniqueTeams.add(team.name);
                    team.persons.forEach(person => {
                        uniquePeople.add(`${person.first_name} ${person.last_name}`);
                    });
                });

                // Add work types
                if (event.work_type) {
                    uniqueTypes.add(event.work_type);
                }
            });

            // Set the extracted values into the state
            setPeople([...uniquePeople]);
            setTeams([...uniqueTeams]);
            setTypes([...uniqueTypes]);
        }
    }, [dropdownData, loading]);

    // Filter for Unassigned events (empty persons and teams, and status not complete or canceled)
    const unassignedEvents = currentEvents?.filter(
        (event) =>
            event.persons.length === 0 &&
            event.teams.length === 0 &&
            event.status !== 'complete' &&
            event.status !== 'canceled'
    );

    // Filter for Reassign events (status postponed or reschedule)
    const reassignEvents = currentEvents?.filter(
        (event) => event.status === 'postponed' || event.status === 'reschedule'
    );

    const handleDateClick = (selected) => {
        setSelectedDateRange({
            start: selected.startStr,
            end: selected.endStr,
            allDay: selected.allDay,
        });
        setAddModalOpen(true);
    };

    const handleEventClick = (selected) => {
        const eventDetails = {
            id: selected.event.id,
            title: selected.event.title,
            start_date: selected.event.start.toISOString(), // Convert start date to ISO format
            end_date: selected.event.end ? selected.event.end.toISOString() : null, // Check for end date
            allDay: selected.event.allDay,
            description: selected.event.description,
            address: selected.event.address,
            ...selected.event.extendedProps, // Spread extendedProps to capture additional data
        };

        setSelectedEvent(eventDetails); // Pass the full event details to the modal // Pass the event details to the modal
        setViewModalOpen(true); // Open the ViewEventModal
    };

    const handleListItemClick = (event) => {
        const eventDetails = {
            id: event.id,
            title: event.title,
            start_date: event.start,
            end_date: event.end,
            allDay: event.allDay,
            description: event.description,
            address: event.address,
            persons: event.persons,
            teams: event.teams,
            status: event.status,
            work_type: event.work_type,
        };

        setSelectedEvent(eventDetails); // Set the selected event details
        setViewModalOpen(true); // Open the ViewEventModal
    };


    return (
        <Box m="20px">
            <Header title="Calendar" subtitle="Full Calendar Interactive Page" />
            {loading ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{
                            vertical: 'top', // Set the vertical position to 'top'
                            horizontal: 'center', // Center horizontally
                        }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={
                                snackbarType === 'success'
                                    ? 'success'
                                    : snackbarType === 'warning'
                                    ? 'warning'
                                    : 'error'
                            }
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarType === 'success'
                                        ? colors.greenAccent[600]
                                        : snackbarType === 'warning'
                                        ? '#FFFF8F'
                                        : colors.redAccent[600],
                                color:
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100],
                            }}
                        >
                            <Typography
                                variant="h6"
                                color={
                                    snackbarType === 'warning'
                                        ? colors.primary[300]
                                        : colors.grey[100]
                                }
                            >
                                {snackbarMessage}
                            </Typography>
                        </Alert>
                    </Snackbar>

                    {/* Toolbar with multi-select menus */}
                    <Box display="flex" justifyContent="space-between" mb="20px">
                        {isMobile && (
                            <IconButton
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon sx={{ fontSize: '2rem' }} />
                            </IconButton>
                        )}
                        {/* People Select */}
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="people-select-label">People</InputLabel>
                            <Select
                                labelId="people-select-label"
                                id="people-select"
                                multiple
                                value={selectedPerson}
                                onChange={handleSelectChange(setSelectedPerson)}
                                input={<OutlinedInput label="People" />}
                                MenuProps={MenuProps}
                            >
                                {people.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, selectedPerson)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Teams Select */}
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="teams-select-label">Teams</InputLabel>
                            <Select
                                labelId="teams-select-label"
                                id="teams-select"
                                multiple
                                value={selectedTeam}
                                onChange={handleSelectChange(setSelectedTeam)}
                                input={<OutlinedInput label="Teams" />}
                                MenuProps={MenuProps}
                            >
                                {teams.map((team) => (
                                    <MenuItem
                                        key={team}
                                        value={team}
                                        style={getStyles(team, selectedTeam)}
                                    >
                                        {team}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Type Select */}
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="type-select-label">Type</InputLabel>
                            <Select
                                labelId="type-select-label"
                                id="type-select"
                                multiple
                                value={selectedType}
                                onChange={handleSelectChange(setSelectedType)}
                                input={<OutlinedInput label="Type" />}
                                MenuProps={MenuProps}
                            >
                                {types.map((type) => (
                                    <MenuItem
                                        key={type}
                                        value={type}
                                        style={getStyles(type, selectedType)}
                                    >
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        {/* CALENDAR SIDEBAR */}
                        {isMobile ? (
                            <Drawer
                            anchor="left"
                            open={isSidebarOpen}
                            onClose={() => setIsSidebarOpen(false)}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    width: '75%',
                                    backgroundColor: colors.primary[400],
                                    height: '100vh',  // Make sure the drawer takes the full viewport height
                                },
                            }}
                        >
                            <Box p="15px" height="100%"> {/* Make sure the entire Box takes up 100% of the height */}
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                        height: '45vh',  // Use 45vh for the first scrollable section
                                        mb: 4,  // Add more space between the sections
                                    }}
                                >
                                    <Typography variant="h5">Unassigned</Typography>
                                    <List>
                                        {unassignedEvents.map((event) => (
                                            <ListItem
                                                key={event.id}
                                                onClick={() => handleListItemClick(event)}
                                                sx={{
                                                    backgroundColor: colors.greenAccent[500],
                                                    margin: '10px 0',
                                                    borderRadius: '2px',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={event.title}
                                                    secondary={
                                                        <Typography>
                                                            {formatDate(event.start, {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                            })}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>

                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                        height: '45vh',  // Use 45vh for the second scrollable section
                                    }}
                                >
                                    <Typography variant="h5">Reassign</Typography>
                                    <List>
                                        {reassignEvents.map((event) => (
                                            <ListItem
                                                key={event.id}
                                                onClick={() => handleListItemClick(event)}
                                                sx={{
                                                    backgroundColor: colors.greenAccent[500],
                                                    margin: '10px 0',
                                                    borderRadius: '2px',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            <Typography display="inline">
                                                                {event.title}
                                                            </Typography>
                                                            <Typography
                                                                display="inline"
                                                                sx={{ marginLeft: '10px' }}
                                                            >
                                                                ({event.status})
                                                            </Typography>
                                                        </>
                                                    }
                                                    secondary={
                                                        <Typography>
                                                            {formatDate(event.start, {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                            })}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        </Drawer>
                        ) : (
                            <Box
                                flex="1 1 20%"
                                backgroundColor={colors.primary[400]}
                                p="15px"
                                borderRadius="4px"
                                sx={{
                                    height: '75vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                {/* Unassigned Events Section */}
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                    }}
                                >
                                    <Typography variant="h5">Unassigned</Typography>
                                    <List>
                                        {unassignedEvents.map((event) => (
                                            <ListItem
                                                key={event.id}
                                                onClick={() => handleListItemClick(event)}
                                                sx={{
                                                    backgroundColor: colors.greenAccent[500],
                                                    margin: '10px 0',
                                                    borderRadius: '2px',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={event.title}
                                                    secondary={
                                                        <Typography>
                                                            {formatDate(event.start, {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                            })}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>

                                {/* Reassign Events Section */}
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                    }}
                                >
                                    <Typography variant="h5">Reassign</Typography>
                                    <List>
                                        {reassignEvents.map((event) => (
                                            <ListItem
                                                key={event.id}
                                                onClick={() => handleListItemClick(event)}
                                                sx={{
                                                    backgroundColor: colors.greenAccent[500],
                                                    margin: '10px 0',
                                                    borderRadius: '2px',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            <Typography display="inline">
                                                                {event.title}
                                                            </Typography>
                                                            <Typography
                                                                display="inline"
                                                                sx={{ marginLeft: '10px' }}
                                                            >
                                                                ({event.status})
                                                            </Typography>
                                                        </>
                                                    }
                                                    secondary={
                                                        <Typography>
                                                            {formatDate(event.start, {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                            })}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        )}
                        {/* CALENDAR */}
                        <Box flex="1 1 100%" ml="15px">
                            <FullCalendar
                                key={calendarKey}
                                height="75vh"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                                }}
                                initialView={currentView}
                                dayMaxEvents={3}  // Maximum number of events to show per day, before "+ more" link appears
                                dayMaxEventRows={true}  // Ensure the number of events displayed is capped
                                editable={true}
                                selectable={true}
                                selectMirror={true}
                                select={handleDateClick}
                                eventClick={handleEventClick}
                                events={filteredEvents} // Use the fetched events
                                datesSet={handleViewChange}
                                eventContent={(eventInfo) => {
                                    const { status } = eventInfo.event.extendedProps;

                                    // Apply strikethrough for 'complete' or 'canceled'
                                    const isStrikethrough = status === 'complete' || status === 'canceled';

                                    return (
                                        <>
                                            <div className="fc-daygrid-event-dot"></div>
                                            <div className="fc-event-time">
                                                {eventInfo.timeText}
                                            </div>
                                            <div className="fc-event-title" style={{ textDecoration: isStrikethrough ? 'line-through' : 'none' }}>
                                                {eventInfo.event.title}
                                            </div>
                                        </>
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                    <AddEventModal
                        isAddModalOpen={isAddModalOpen}
                        setAddModalOpen={setAddModalOpen}
                        setSnackbarMessage={setSnackbarMessage}
                        setSnackbarType={setSnackbarType}
                        setSnackbarOpen={setSnackbarOpen}
                        selectedDateRange={selectedDateRange}  // Pass the selected date range
                    />
                    <ViewEventModal
                        isViewModalOpen={isViewModalOpen}
                        setViewModalOpen={setViewModalOpen}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        setSnackbarMessage={setSnackbarMessage}
                        setSnackbarType={setSnackbarType}
                        setSnackbarOpen={setSnackbarOpen}
                    />
                </>
            )}
        </Box>
    );
};

export default Calendar;
